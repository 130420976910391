<template>
    <div class="signup-form">
        <div class="signup-content">
            <div class="form-title">
                <div class="element">
                    <div class="title-form">
                        {{ $t('public.signup.title') }}
                    </div>
                </div>
                <div class="element">
                    <div class="description">
                        {{ $t('public.signup.description') }}
                    </div>
                </div>
            </div>
            <!-- Name -->
            <div class="form-content">
                <form>
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="lead.name.trim().length > 0">{{ $t('signup.form.name') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" data-hj-allow v-model.trim="lead.name" class="name" :class="{ filled: lead.name.trim().length > 0, error: error.name }" :placeholder="$t('signup.form.name')" />
                        </div>
                        <div class="errorLabel" v-if="error.name">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Surname -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="lead.surname.trim().length > 0">{{ $t('signup.form.surnames') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" data-hj-allow v-model.trim="lead.surname" class="name" :class="{ filled: lead.surname.trim().length > 0, error: error.surname }" :placeholder="$t('signup.form.surnames')" />
                        </div>
                        <div class="errorLabel" v-if="error.surname">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Email -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="lead.email.trim().length > 0">{{ $t('public.signup.email_user') }}</div>
                        </div>
                        <div class="input">
                            <input type="email" data-hj-allow v-model.trim="lead.email" class="email" :class="{ filled: lead.email.trim().length > 0, error: error.email }" :placeholder="$t('public.signup.email_user')" />
                        </div>
                        <div class="errorLabel" v-if="error.email">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                                <div class="normal">{{ $t('signup.form.error.email') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Phone -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="lead.phone.trim().length > 0">{{ $t('signup.form.phone') }}</div>
                        </div>
                        <div class="input">
                            <input type="phone" data-hj-allow v-model.trim="lead.phone" class="phone" :class="{ filled: lead.phone.trim().length > 0, error: error.phone }" :placeholder="$t('signup.form.phone')" />
                        </div>
                        <div class="errorLabel" v-if="error.phone">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Company -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="lead.company.trim().length > 0">{{ $t('signup.form.company') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" data-hj-allow v-model="lead.company" class="company" :class="{ filled: lead.company.trim().length > 0, error: error.company }" :placeholder="$t('signup.form.company')" />
                        </div>
                        <div class="errorLabel" v-if="error.company">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Locals -->
                    <div class="element sites">
                        <div class="label">
                            <div class="value" v-if="lead.locals.trim().length > 0">{{ $t('signup.form.sites.title') }}</div>
                        </div>
                        <div class="input">
                            <input type="number" data-hj-allow oninput="validity.valid||(value='');" min="1" v-model.trim="lead.locals" class="company" :class="{ filled: lead.locals.trim().length > 0, error: error.locals }" :placeholder="$t('signup.form.sites.title')" />
                        </div>
                        <div class="errorLabel" v-if="error.locals">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="footer-form">
                <!-- Footer Buttons -->
                <div class="element send">
                    <!-- <div v-if="validButton" class="send-button" @click="createLead()">
                {{ $t('signup.form.save') }}
            </div>
            <div v-else class="send-button inactive">
                {{ $t('signup.form.save') }}
            </div> -->
                    <div class="return" @click="returnStep()">
                        {{ $t('signup.form.return') }}
                    </div>
                    <div class="next" @click="nextStep()">
                        {{ $t('public.signup.next') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'leadForm',
    data() {
        return {
            lead: {
                name: '',
                surname: '',
                email: '',
                phone: '',
                company: '',
                locals: '',
                type: 'signup',
                partnerId: ''
            },
            error: {
                name: false,
                surname: false,
                email: false,
                phone: false,
                company: false,
                locals: false,
                terms_and_conditions: false
            },
            emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    computed: {
        validButton() {
            let showErrors = false
            let validForm = this.validateForm(showErrors)
            return validForm
        }
    },
    methods: {
        nextStep() {
            this.validateForm().then(response => {
                if (response) {
                    var self = this
                    var language = localStorage.getItem('language')
                    // format correct language
                    var lang = ''
                    if (language == 'es') {
                        lang = 'es_ES'
                    } else if (language == 'en') {
                        lang = 'en_US'
                    } else if (language == 'de') {
                        lang = 'de_DE'
                    } else if (language == 'pt') {
                        lang = 'pt_PT'
                    } else if (language == 'it') {
                        lang = 'it_IT'
                    } else if (language == 'fr'){
                        lang = 'fr_FR'
                    }else {
                        lang = 'es_ES'
                    }
                    this.lead.lang = lang
                    if (localStorage.getItem('lead') != null) {
                        var lead = JSON.parse(localStorage.getItem('lead'))

                        this.lead.id = lead.id
                        this.$overlay.loading()
                        this.$store.dispatch('lead/editLead', this.lead).then(function(response) {
                            localStorage.setItem('lead', JSON.stringify(response.data))
                            self.$overlay.hide()
                            self.$emit('nextStep')
                        })
                    } else {
                        this.$overlay.loading()
                        this.$store.dispatch('lead/addLead', this.lead).then(function(response) {
                            localStorage.setItem('lead', JSON.stringify(response.data))
                            self.$gtag.event('complete_lead', {
                                event_category: 'signup'
                            })
                            self.$overlay.hide()
                            self.$emit('nextStep')
                        })
                    }
                }
            })
        },
        returnStep() {
            this.$emit('previousStep')
        },
        async validateForm(showErrors = true) {
            let validate = true

            // Name
            if (this.lead.name == '') {
                this.error.name = true
                validate = false
            } else {
                this.error.name = false
            }

            // Surname
            if (this.lead.surname == '') {
                this.error.surname = true
                validate = false
            } else {
                this.error.surname = false
            }

            // Email
            if (this.lead.email == '') {
                this.error.email = true
                validate = false
            }

            if (showErrors && !this.emailReg.test(this.lead.email)) {
                this.error.email = true
                validate = false
            } else {
                this.error.email = false
            }

            // Phone
            if (this.lead.phone == '') {
                this.error.phone = true
                validate = false
            } else {
                this.error.phone = false
            }

            // Company
            if (this.lead.company == '') {
                this.error.company = true
                validate = false
            } else {
                this.error.company = false
            }

            // Locals
            if (this.lead.locals == '') {
                this.error.locals = true
                validate = false
            } else {
                this.error.locals = false
            }

            return validate
        },

        load() {
            if (localStorage.getItem('lead')) {
                this.lead = JSON.parse(localStorage.getItem('lead'))
            }
        },
        getPartnerId() {
            const partnerId = typeof this.$route.query.pid !== 'undefined' ? this.$route.query.pid : ''
            this.lead.partnerId = partnerId
        }
    },
    mounted() {
        this.load()
        this.getPartnerId()
    }
}
</script>

<style lang="scss" scoped>
.signup-form {
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 12px;
    padding-top: 100px;

    .signup-content {
        width: 100%;
        height: 100%;
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-between);
        max-width: 400px;

        .form-content,
        .form-title,
        .footer-form {
            width: 100%;

            .element {
                height: auto;
                min-height: 50px;
                width: 100%;
                margin: 0 0 10px 0;
                padding: 0;

                .label {
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    height: 20px;
                    width: 100%;
                    .value {
                        height: 100%;
                        width: 300px;
                        @include font-size(sm);
                        color: $inactive-s30;
                    }
                }

                .input {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    @include border-radius(3px);
                    height: 55px;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    input {
                        @include font-size(m);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: #000;
                        padding: 10px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.name {
                            @include background($image: img('profile_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('profile_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.email {
                            @include background($image: img('mail_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('mail_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.phone {
                            @include background($image: img('phone_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('phone_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.company {
                            @include background($image: img('site_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('site_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        &::placeholder {
                            @include font-size(m);
                            font-family: $text;
                            color: $inactive-s30;
                        }
                    }
                }

                .errorLabel {
                    @include display-flex();
                    @include justify-content();
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    width: 100%;

                    .value {
                        width: 100%;

                        .bold {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text-bold;
                            margin-right: 2px;
                        }

                        .normal {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text;
                        }
                    }
                }

                &.sites {
                    select {
                        @include font-size(m);
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: $inactive-s30;
                        padding: 9px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.company {
                            @include background($image: img('location_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('location_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        option[value=''][disabled] {
                            display: none;
                        }

                        &.filled {
                            color: #000;
                        }
                    }
                }

                .options {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);

                    .option {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        border: 1px solid $neutro;
                        height: 40px;
                        width: auto;
                        margin: 5px;
                        padding: 12px;
                        cursor: pointer;

                        &.selected {
                            @include background($color: $second);
                            color: #ffffff;

                            &.error {
                                border: 1px solid $neutro-t80;
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }
                    }
                }

                // &.sites.desktop {
                //     height: auto;
                // }

                // &.sites.mobile {
                //     display: none;
                // }

                &.terms_and_conditions {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    margin: 0;

                    .checkbox {
                        width: auto;
                        margin-right: 6px;

                        input {
                            width: 15px;
                            height: 15px;
                            cursor: pointer;
                        }
                    }

                    .description {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);

                        .part1 {
                            cursor: pointer;

                            &.error {
                                color: $error;
                            }
                        }

                        .part2 {
                            margin-left: 5px;

                            a {
                                text-decoration: underline;
                                text-decoration-color: $second;
                            }

                            &.error {
                                color: $error;
                            }
                        }
                    }
                }

                &.send {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items();

                    .return {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second-t90;
                        color: $second-dark;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-t80;
                        }
                    }
                    .next {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second;
                        color: #ffffff;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-s20;
                        }
                    }
                }
                .title-form {
                    @include font-size(l);
                    text-align: center;
                    font-family: $text-bold;
                    color: $second;
                }
                .description {
                    @include font-size(m);
                    text-align: center;
                    font-family: $text-light;
                    color: $neutro-s70;
                }
            }
        }
        .footer-form {
            @include display-flex();
            @include align-items(flex-end);

            .element {
                margin: 0px;
            }
        }

        @media screen and (min-height: 840px) {
            // background-color: red;
        }
    }
}
</style>
