<template>
    <div class="menu-top">
        <div class="logo" @click="returnHomePage()"></div>

        <div class="language">
            <select v-model="language" @change="changeLang()">
                <option v-for="language in languages" :data="language" :key="language">{{ language }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuTop',
    data() {
        return {
            language: '',
            languages: ['es', 'en', 'pt', 'de', 'fr', 'it']
        }
    },
    computed: {},
    methods: {
        returnHomePage() {
            let url = ''

            switch (this.language) {
                case 'es':
                    url = 'https://andyapp.io'
                    break
                case 'en':
                    url = 'https://andyapp.io/en'
                    break
                case 'pt':
                    url = 'https://andyapp.io'
                    break
            }

            window.location.replace(url)
        },

        changeLang() {
            this.$store.commit('changeLanguage', this.language)
            localStorage.setItem('language', this.language)
        }
    },
    created() {
        // set language
        const { lang } = this.$route.query
        if (lang && this.languages.includes(lang)) {
            this.language = lang
        } else {
            this.language = 'es'
        }

        this.$store.commit('changeLanguage', this.language)
    }
}
</script>

<style lang="scss" scoped>
.menu-top {
    @include display-flex();
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items();
    @include background($color: #ffffff);
    height: 70px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.25);
    position: fixed;
    top: 0;
    padding: 0 150px;
    z-index: $z-menu-aux;

    .logo {
        @include background($image: img('logo_andy.png'), $size: contain, $position: center center);
        width: 140px;
        height: 50px;
        cursor: pointer;
        -webkit-backface-visibility: hidden;
    }

    .language {
        @include border-radius(3px);
        @include background($color: #ffffff, $image: img('down_caret_neutro_s90.svg'), $size: 8px 8px, $position: center right 10px);
        border: 1px solid $neutro-s10;
        width: 62px;
        height: 43px;

        select {
            @include font-size(m);
            font-family: $text;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            cursor: pointer;
            padding-left: 10px;
            text-transform: uppercase;
        }

        option {
            @include font-size(m);
            font-family: $text;
        }
    }
}

@media screen and (max-width: 960px) {
    .menu-top {
        padding: 0 20px;
    }
}
</style>
